<div class="gotop">
  <app-header></app-header>
</div>
<app-solutionsfor></app-solutionsfor>
<app-advantage></app-advantage>
<app-divert></app-divert>
<app-features></app-features>
<app-terminal></app-terminal>
<!--<app-complect></app-complect>-->
<app-questions></app-questions>
